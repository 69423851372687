import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { CommonService } from '../../services/common.service';
import { Meta, Title } from '@angular/platform-browser';
import { SyncService } from '../../services/sync.service';
import { CikkService } from '../../services/cikk.service';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { UserService } from '../../services/user.service';
import { Constants } from '../../constants';
import { HttpClient } from '@angular/common/http';

@Component({
  	selector: 'app-adatmod',
  	templateUrl: './adatmod.component.html',
	styleUrls: ['./adatmod.component.sass', '../reg/reg.component.sass'],
	encapsulation: ViewEncapsulation.None
})
export class AdatmodComponent implements OnInit {

	szavak: any;
	siteurl: any;
  	menu: any;
  	localStorage: Storage;
  	lang: any = "hu";
  	shopcode: any;
  	alias: any = false;
  	reg: any;
	cikk: any;
	error: any = true;
	user: any;
	hiba: any = "";
	forgot: any;
	submitted: any = false;
	up: any = false;
	loading: any = false;
	szall_1_show: any = false;
	szall_2_show: any = false;
	szall_3_show: any = false;
	szall_4_show: any = false;
	szall_5_show: any = false;
	regtype: any;
	szam_szall_ua : any = false;
	userInfo: any;
	adataim: any;
	uid: any;
	user_tipus: any;

  	regform = new FormGroup({
	  	files: new FormControl('', []),
	  	users_id: new FormControl('', []),
	  	szall_kernev: new FormControl('', []),
	 	tel: new FormControl('', [Validators.required]),
	  	email: new FormControl('', [Validators.email,Validators.required]),
	  	szam_irsz: new FormControl('', [Validators.required]),
	  	szam_varos: new FormControl('', [Validators.required]),
	  	szam_utca: new FormControl('', [Validators.required]),
		szall_1_cegnev: new FormControl('', []),
		szall_1_irsz: new FormControl('', []),
		szall_1_varos: new FormControl('', []),
		szall_1_utca: new FormControl('', []),
		szall_1_mukodes: new FormControl('', []),
		szall_1_mukodes_nev: new FormControl('', []),
		szall_1_mukodes_src: new FormControl('', []),
		szall_2_cegnev: new FormControl('', []),
		szall_2_irsz: new FormControl('', []),
		szall_2_varos: new FormControl('', []),
		szall_2_utca: new FormControl('', []),
		szall_2_mukodes: new FormControl('', []),
		szall_2_mukodes_nev: new FormControl('', []),
		szall_2_mukodes_src: new FormControl('', []),
		szall_3_cegnev: new FormControl('', []),
		szall_3_irsz: new FormControl('', []),
		szall_3_varos: new FormControl('', []),
		szall_3_utca: new FormControl('', []),
		szall_3_mukodes: new FormControl('', []),
		szall_3_mukodes_nev: new FormControl('', []),
		szall_3_mukodes_src: new FormControl('', []),
		szall_4_cegnev: new FormControl('', []),
		szall_4_irsz: new FormControl('', []),
		szall_4_varos: new FormControl('', []),
		szall_4_utca: new FormControl('', []),
		szall_4_mukodes: new FormControl('', []),
		szall_4_mukodes_nev: new FormControl('', []),
		szall_4_mukodes_src: new FormControl('', []),	
		szall_5_cegnev: new FormControl('', []),
		szall_5_irsz: new FormControl('', []),
		szall_5_varos: new FormControl('', []),
		szall_5_utca: new FormControl('', []),
		szall_5_mukodes: new FormControl('', []),
		szall_5_mukodes_nev: new FormControl('', []),
		szall_5_mukodes_src: new FormControl('', [])
  	});

	addForm(){
		if(this.alias === 'maganszemely'){
			this.regform.registerControl('veznev',new FormControl('', [
				Validators.required
			]));
			this.regform.registerControl('kernev',new FormControl('', [
				Validators.required
			]));
			this.regform.registerControl('szam_veznev',new FormControl('', [
				Validators.required
			]));
			this.regform.registerControl('szam_kernev',new FormControl('', [
				Validators.required
			]));
			this.regform.registerControl('szall_veznev',new FormControl('', [
				Validators.required
			]));
			this.regform.registerControl('szall_kernev',new FormControl('', [
				Validators.required
			]));
			this.regform.registerControl('szall_irsz',new FormControl('', [
				Validators.required
			]));
			this.regform.registerControl('szall_varos',new FormControl('', [
				Validators.required
			]));
			this.regform.registerControl('szall_utca',new FormControl('', [
				Validators.required
			]));
		}
		if(this.alias === 'viszontelado'){
			this.regform.addControl('cegnev',new FormControl('', [
				Validators.required
			]));
			this.regform.addControl('kapcs',new FormControl('', [
				Validators.required
			]));
			this.regform.addControl('adoszam',new FormControl('', [
				Validators.required
			]));
			this.regform.addControl('szall_0_mukodes',new FormControl('', [
				
			]));
			this.regform.addControl('szall_0_mukodes_nev',new FormControl('', [
				
			]));
			this.regform.addControl('szall_0_mukodes_src',new FormControl('', [
				
			]));
			this.regform.addControl('szam_ado',new FormControl('', [
				Validators.required
			]));
			this.regform.addControl('szam_cegnev',new FormControl('', [
				Validators.required
			]));
		}
	}

	egyezik(){
		if(this.szam_szall_ua === false){
			this.szam_szall_ua = true;
			if(this.alias === "maganszemely"){
				this.regform.controls.szall_kernev.setValue(this.regform.controls.szam_kernev.value);
				this.regform.controls.szall_veznev.setValue(this.regform.controls.szam_veznev.value);
				this.regform.controls.szall_irsz.setValue(this.regform.controls.szam_irsz.value);
				this.regform.controls.szall_varos.setValue(this.regform.controls.szam_varos.value);
				this.regform.controls.szall_utca.setValue(this.regform.controls.szam_utca.value);
			}
		}
		else{
			this.szam_szall_ua = false;
		}
	}

	onFileChange(event){
		const formData = new FormData();
		formData.append('users_id', this.userInfo.users_id);
		this.up = true;
		let name = event.target.attributes.formcontrolname.value;
		if (event.target.files.length > 0) {
			const file = event.target.files[0];
			if(name === "szall_0_mukodes"){
				this.regform.patchValue({
					szall_0_mukodes_src: file
				});
				formData.append('szall_0_mukodes', this.regform.controls.szall_0_mukodes_src.value);
				this.http.post(Constants.endpoint+'rest/upload.php', formData)
				.subscribe(res => {
					this.regform.patchValue({
						szall_0_mukodes_nev: res["0"].kep
					});
				});  
			}
			if(name === "szall_1_mukodes"){
				this.regform.patchValue({
					szall_1_mukodes_src: file
				});
				formData.append('szall_1_mukodes', this.regform.controls.szall_1_mukodes_src.value);
				this.http.post(Constants.endpoint+'rest/upload.php', formData)
				.subscribe(res => {
					this.regform.patchValue({
						szall_1_mukodes_nev: res["0"].kep
					});
				});  
			}
			if(name === "szall_2_mukodes"){
				this.regform.patchValue({
					szall_2_mukodes_src: file
				});
				formData.append('szall_2_mukodes', this.regform.controls.szall_2_mukodes_src.value);
				this.http.post(Constants.endpoint+'rest/upload.php', formData)
				.subscribe(res => {
					this.regform.patchValue({
						szall_2_mukodes_nev: res["0"].kep
					});
				});  
			}
			if(name === "szall_3_mukodes"){
				this.regform.patchValue({
					szall_3_mukodes_src: file
				});
				formData.append('szall_3_mukodes', this.regform.controls.szall_3_mukodes_src.value);
				this.http.post(Constants.endpoint+'rest/upload.php', formData)
				.subscribe(res => {
					this.regform.patchValue({
						szall_3_mukodes_nev: res["0"].kep
					});
				});  
			}
			if(name === "szall_4_mukodes"){
				this.regform.patchValue({
					szall_4_mukodes_src: file
				});
				formData.append('szall_4_mukodes', this.regform.controls.szall_4_mukodes_src.value);
				this.http.post(Constants.endpoint+'rest/upload.php', formData)
				.subscribe(res => {
					this.regform.patchValue({
						szall_4_mukodes_nev: res["0"].kep
					});
				});  
			}
			if(name === "szall_5_mukodes"){
				this.regform.patchValue({
					szall_5_mukodes_src: file
				});
				formData.append('szall_5_mukodes', this.regform.controls.szall_5_mukodes_src.value);
				this.http.post(Constants.endpoint+'rest/upload.php', formData)
				.subscribe(res => {
					this.regform.patchValue({
						szall_5_mukodes_nev: res["0"].kep
					});
				});  
			}
			
		}
	}
  
	onSubmitMod() {
		this.error = true;
		this.submitted = true;
		if(this.alias === 'maganszemely'){

		}
		if(this.alias === 'viszontelado'){
			
		}
		Object.keys(this.regform.controls).forEach(key => {
			if(this.regform.controls[key].errors != null){
				this.error = false;
				
			}
		});
		if (this.error === false) {
			return;
		}
		else{
			this.loading = true;
			/*if(this.up === true){
				const formData = new FormData();
				formData.append('users_id', this.userInfo.users_id);
				formData.append('szall_0_mukodes', this.regform.controls.szall_0_mukodes_src.value);
				formData.append('szall_1_mukodes', this.regform.controls.szall_1_mukodes_src.value);
				formData.append('szall_2_mukodes', this.regform.controls.szall_2_mukodes_src.value);
				formData.append('szall_3_mukodes', this.regform.controls.szall_3_mukodes_src.value);
				formData.append('szall_4_mukodes', this.regform.controls.szall_4_mukodes_src.value);
				formData.append('szall_5_mukodes', this.regform.controls.szall_5_mukodes_src.value);
				this.http.post(Constants.endpoint+'/rest/upload.php', formData)
				.subscribe(res => {
					this.regform.patchValue({
						files: res,
						users_id: res['users_id']
					});
					this.userService.adatmod(btoa(unescape(encodeURIComponent(JSON.stringify(this.regform.value)))),"hu",this.up, this.alias, this.uid).subscribe((data: any) => {
						this.commonService.shopcode().subscribe((data: any) => {
							this.shopcode = data.shop_code;
							this.userService.getuser(this.uid).subscribe((data: any) => {
								this.user = data[0];
								this.localStorage.setItem(this.shopcode+"_u", JSON.stringify(this.user));
								this.userService.set_userInfo(this.user);
								this.submitted = false;
								this.regform.reset();
								this.loading = false;
								this.route.navigate(["sikeres-adatmodositas"]);
							});
						});
					});
				});
			}*/
			this.userService.adatmod(btoa(unescape(encodeURIComponent(JSON.stringify(this.regform.value)))),"hu",this.up, this.alias, this.uid).subscribe((data: any) => {
				this.commonService.shopcode().subscribe((data: any) => {
					this.shopcode = data.shop_code;
					this.userService.getuser(this.uid).subscribe((data: any) => {
						this.user = data[0];
						this.localStorage.setItem(this.shopcode+"_u", JSON.stringify(this.user));
						this.userService.set_userInfo(this.user);
						this.submitted = false;
						this.regform.reset();
						this.loading = false;
						this.route.navigate(["sikeres-adatmodositas"]);
					});
				});
			});
		}
	}

  	get f() { return this.regform.controls; }
  
	constructor(private http: HttpClient, private userService: UserService, public dialog: MatDialog, private route: Router, private cikkService: CikkService, public sync: SyncService,private title: Title, private meta: Meta, private router: ActivatedRoute, private commonService: CommonService) {
		this.localStorage = window.localStorage;
	}

	get isLocalStorageSupported(): boolean {
		return !!this.localStorage
	}
	
	openCim(id){
		if(id === 1){
			if(this.szall_1_show === true){
				this.szall_1_show = false;
			}
			else{
				this.szall_1_show = true;
			}
		}
		if(id === 2){
			if(this.szall_2_show === true){
				this.szall_2_show = false;
			}
			else{
				this.szall_2_show = true;
			}
		}
		if(id === 3){
			if(this.szall_3_show === true){
				this.szall_3_show = false;
			}
			else{
				this.szall_3_show = true;
			}
		}
		if(id === 4){
			if(this.szall_4_show === true){
				this.szall_4_show = false;
			}
			else{
				this.szall_4_show = true;
			}
		}
		if(id === 5){
			if(this.szall_5_show === true){
				this.szall_5_show = false;
			}
			else{
				this.szall_5_show = true;
			}
		}
	}

	ngOnInit(): void {
		this.cikkService.getcikk('adataim',this.lang).subscribe((data: {}) => {
			this.cikk = data;
		});
		this.commonService.shopcode().subscribe((data: any) => {
			this.shopcode = data.shop_code;
			if(this.localStorage.getItem(this.shopcode+"_u") != null){
				this.userInfo = JSON.parse(this.localStorage.getItem(this.shopcode+"_u"));
				console.log(this.userInfo);
				if(this.userInfo.users_tipus === 'privat'){
					this.alias = "maganszemely";
				}
				if(this.userInfo.users_tipus === 'ceg'){
					this.alias = "viszontelado";
				}
				this.uid = this.userInfo.users_id;
				this.addForm();
				this.regform.patchValue({
					users_id: this.userInfo.users_id,
					tel: this.userInfo.users_tel1,
					email: this.userInfo.users_email,
					szam_irsz: this.userInfo.users_irsz1,
					szam_varos: this.userInfo.users_telep1,
					szam_utca: this.userInfo.users_utca_hsz1,
					szall_1_cegnev: this.userInfo.szall_1_cegnev,
					szall_1_irsz: this.userInfo.szall_1_irsz,
					szall_1_varos: this.userInfo.szall_1_varos,
					szall_1_utca: this.userInfo.szall_1_utca,
					szall_2_cegnev: this.userInfo.szall_2_cegnev,
					szall_2_irsz: this.userInfo.szall_2_irsz,
					szall_2_varos: this.userInfo.szall_2_varos,
					szall_2_utca: this.userInfo.szall_2_utca,
					szall_3_cegnev: this.userInfo.szall_3_cegnev,
					szall_3_irsz: this.userInfo.szall_3_irsz,
					szall_3_varos: this.userInfo.szall_3_varos,
					szall_3_utca: this.userInfo.szall_3_utca,
					szall_4_cegnev: this.userInfo.szall_4_cegnev,
					szall_4_irsz: this.userInfo.szall_4_irsz,
					szall_4_varos: this.userInfo.szall_4_varos,
					szall_4_utca: this.userInfo.szall_4_utca,
					szall_5_cegnev: this.userInfo.szall_5_cegnev,
					szall_5_irsz: this.userInfo.szall_5_irsz,
					szall_5_varos: this.userInfo.szall_5_varos,
					szall_5_utca: this.userInfo.szall_5_utca,
					veznev: this.userInfo.users_vnev1,
					kernev: this.userInfo.users_knev1,
					szam_veznev: this.userInfo.users_vnev2,
					szam_kernev: this.userInfo.users_knev2,
					szall_veznev: this.userInfo.users_vnev3,
					szall_kernev: this.userInfo.users_knev3,
					szall_irsz: this.userInfo.users_irsz2,
					szall_varos: this.userInfo.users_telep2,
					szall_utca: this.userInfo.users_utca_hsz2,
					cegnev: this.userInfo.users_cegnev1,
					kapcs: this.userInfo.users_kapcs_tart,
					adoszam: this.userInfo.users_ado,
					szam_ado: this.userInfo.users_ado2,
					szam_cegnev: this.userInfo.users_cegnev2
				});
			}
		});
		this.commonService.getword(this.lang,"magan-viszont-kotelezo-veznev-kernev-cegnev-kapcsnev-tel-email-pw-pw2-hibas-minkar-nagysz-ketj-adoszam-muke-tallozas-szamlazasi_cim-irsz-varos-utcahsz-szallitasi_cim-szallitasi_azonos_szamlazasival-cim-elfogadom-reg").subscribe((data: {}) => {
			this.szavak = data;
		});
		this.commonService.menuroww("77-41",this.lang).subscribe((data: {}) => {
			this.adataim = data[0];
			this.reg = data[1];
			this.commonService.siteurl().subscribe((data: any) => {
				this.siteurl = data;
				this.title.setTitle(this.adataim.title);
				this.meta.updateTag(
					{ name: 'description', content: this.adataim.desc }
				);
				this.meta.updateTag(
					{ name: 'og:title', content: this.adataim.seotitle }
				);
				this.meta.updateTag(
					{ name: 'og:description', content: this.adataim.desc }
				);
				this.meta.updateTag(
					{ name: 'dc.publisher', content: this.adataim.seotitle }
				);
				this.meta.updateTag(
					{ name: 'dc.title', content: this.adataim.seotitle }
				);
				this.meta.updateTag(
					{ name: 'dc.description', content: this.adataim.desc }
				);
				this.meta.updateTag(
					{ name: 'og:url', content: this.siteurl.siteurl+this.adataim.alias }
				);
			});
		});
  	}
}
